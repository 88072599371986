import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import loader from "../../methods/loader";
import { useHistory } from "react-router-dom";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CiAlignRight } from "react-icons/ci";
import { MdDataObject } from "react-icons/md";
import ModalOKR from "./ModalOKR";
import { useDispatch, useSelector } from "react-redux";
import AlignmentOKR from "./AlignmentOKR";
import { LuCircleDotDashed } from "react-icons/lu";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import { sessions } from "../../actions/session";
import Select from "react-select";
import { Menu } from "@headlessui/react";
import { CiFilter } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import ChildModalOKR from "./ChildModalOKR";
import methodModel from "../../methods/methods";
import InfiniteScroll from 'react-infinite-scroll-component';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FaArrowDown, FaArrowRight, FaRegCalendarAlt, FaUserCircle } from 'react-icons/fa';


const OKRindex = ({ layout = true, ownerID, sessionId, getcounts }) => {


  const { id } = useParams();
  const [LoadingState, setLoadingState] = useState(false);
  const getsessionId = localStorage.getItem("sessionID");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sessionss = useSelector((state) => state.session);
  const SessionFilter = sessionss?.data
    ? { label: sessionss?.data, value: id }
    : "";
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({
    page: 1,
    count: 12,
    search: "",
    ownerId: "",
    sessionId: sessionId,
  });
  const [SessionIdFilter, setSessionIdFilter] = useState(SessionFilter);
  const [childKeyResult, setchildKeyResult] = useState("");
  const [loading, setloading] = useState(false);
  const [total, settotal] = useState(0);
  const [child, setchild] = useState(0);
  const [detail, setdetail] = useState(null);
  const navigate = useHistory();
  // Create OKRs
  const [session, setsessions] = useState();
  const [owners, setowners] = useState([]);
  const [multiOptions, setmultiOptions] = useState([]);
  const [form, setform] = useState({ session: "", title: "", ownerIds: [], contributors: [], whyNow: '' });
 
  const [keyResult, setkeyResult] = useState({
    title: "",
    unit: "",
    targetNumber: "",
    initialNumber: "",
    description: "",
    ownerIds: [],
    deadline: "no custom deadline",
    softDeadline: "",
    hardDeadline: "",
    session: null,
  });
  const [editor, seteditor] = useState({ keyResult: "", noteKeyResult: "" });
  const [updateKeyResult, setupdateKeyResult] = useState({
    newValue: "",
    confidenceLevel: "high",
    note: "",
    dateOfUpdate: new Date(),
    okrId: "",
    goal: "",
    currentValue: "",
    keyResultId: "",
  });
  const [childObjective, setChildObjective] = useState();
  const [keyResultData, setkeyResultData] = useState();
  const [parentKeyResult, setparentKeyResult] = useState();
  const [dataResources, setResources] = useState([]);
  const [newupdated, setnewupdated] = useState({
    newValue: "",
    confidenceLevel: "high",
    note: "",
    dateOfUpdate: new Date(),
    okrId: "",
    goal: "",
    currentValue: "",
    keyResultId: "",
  });

  useEffect(() => {
    let str = ownerID?.map((itm) => itm.value)?.toString() || '';
    let flt = {
      ownerId: str
    }

    if (user?.role == "employee") {


    } else {
      flt.addedBy = user?.id || user?._id || '';
    }
    getData({ ...flt });
    setfilter({ ...filter, ...flt });
  }, [id]);

  useEffect(() => {
    getSessions();
    getOwners();
    if (id) {
      getSessionDetail();
    }
    if (sessionss?.data) {
      // localStorage.setItem("sessionNAme", sessionss?.data);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      let el = document.getElementById("pills-objective-tab");
      if (el) {
        el.click();
      }
    }
  }, [id, user]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const getData = (p = {}, load = true, page = 1) => {
    let filt = {
      ...filter,
      sessionId: id || sessionId || getsessionId,
      keyType: "okr,child_objective",
      sortBy: "createdAt desc",
      page,
      ...p,
    };

    if (user?.role !== "employee") {
      filt = { ...filt, addedBy: user?.id || user?._id };
    } else {
      filt = { ...filt, addedBy: user?.addedBy || user?.addedBy };
    }

    loader(true);
    setLoadingState(true);
    ApiClient.get(`okrs`, filt).then((res) => {
      setLoadingState(false);
      if (res.success) {
        const newData = res?.data;
        if (page === 1) {
          setdata(newData);
        } else {
          setdata((prevData) => {
            const combinedData = [...prevData, ...newData];
            const uniqueData = combinedData.filter((item, index, self) =>
              index === self.findIndex((t) => t._id === item._id)
            );
            return uniqueData;
          });
        }
        settotal(res?.total);
        setChildObjective(res?.data.childObjective);
        if ((detail?.id || detail?._id) && load) {
          setdetail(
            res?.data?.find(
              (item) => item?._id === detail?.id || item?._id === detail?._id
            )
          );
        }
        if (res?.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
      loader(false);
    },);
  };

  useEffect(() => {
    getData({}, true, 1);
  }, []);

  const fetchMoreData = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      getData({}, true, nextPage);
      return nextPage;
    });
  };

  const [OKRListing, setOKRListing] = useState([]);

  const getOKRListing = () => {
    loader(true)
    ApiClient.get(`okrs?sessionId=${id}&level=0&page=1&count=10`).then(async (res) => {
      if (res.success) {
        let data = res?.data.map(itm => {
          itm.child = []
          return itm
        })

        let i = 0;
        for await (let item of data) {
          let child = []
          if (item.childExist) {
            const res = await ApiClient.get(`okrs?okrId=${item?.id || item?._id}&level=${item?.level + 1}`)
            if (res.success) {
              child = res.data
            }
          }
          data[i].child = child
          i++
        }
        setOKRListing(data);
      }
      loader(false)
    });
  };


  const getSessionDetail = () => {
    ApiClient.get(`session?id=${id || getsessionId}`).then((res) => {
      if (res.data?.id) {
        setform({
          ...form,
          session: { value: res?.data?.id, label: res?.data?.title },
        });
      }
    });
  };

  const getSessions = () => {
    let payload = {
      isDeleted: false,
    };
    if (user?.role == "employee") {
      payload = {
        ...payload,
        addedBy: user?.addedBy,
        sortBy: "start_date asc",
        // active: true
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      };
    } else {
      payload = {
        isDeleted: false,
        addedBy: user?.id || user?._id,
        sortBy: "start_date asc",
        // active: true
      };
    }
    setLoadingState(true);
    ApiClient.get(`sessions`, payload).then((res) => {
      if (res.success) {
        setResources(res?.data);
        setLoadingState(false);
        setsessions(
          res?.data?.map((item) => {
            return { value: item?._id || item?.id, label: item?.title };
          })
        );
        if (
          !localStorage.getItem("ownerId") &&
          !localStorage.getItem("sessionID")
        ) {
          // getData({ sessionId: res?.data[0]?._id || res?.data[0]?.id });
          // getcounts(res?.data[0]?._id || res?.data[0]?.id)
          // localStorage.setItem("sessionID",res?.data[0]?._id || res?.data[0]?.id)
          // localStorage.setItem("sessionNAme",res?.data[0]?.title)
          // localStorage.setItem("endate",res?.data[0]?.end_date)
        }
      }
    });
  };

  const confidenceLevelClass = (item) => {
    let value = `${item?.confidenceLevel ==
      "low" ||
      item?.confidenceLevel ==
      ""
      ? "red-color"
      : item?.confidenceLevel ==
        "medium"
        ? "orange-color"
        : item?.confidenceLevel ==
          "high"
          ? "green-color"
          : ""
      }`

    if ((item?.keyType == "child_objective" || item?.keyType == "okr")) {
      value = ''
    }

    return value
  }

  const getKeyResults = (level=0) => {
    // loader(true);
    ApiClient.get(`okrs`, {
      sessionId:id,
      level:level,
      // okrId: id ? id : detail?.id ? detail?.id : detail?._id,
      addedBy: user?.role == "employee" ? user?.id || user?._id : ''
    }
    ).then((res) => {
      if (res.success) {
        setkeyResultData(res?.data);
        setparentKeyResult(
          res?.data?.map((item) => {
            return { value: item?._id, label: item?.title };
          })
        );
      }
      // loader(false);
    });
  };

  const clearFields = () => {
    setform({ ...form, title: "", ownerIds: "", whyNow: '', contributors: "" });
  };

  const getOwners = () => {
    ApiClient.get(`users/list?status=active&addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        ApiClient.get(`teams?addedBy=${user?.id || user?._id}`).then((res1) => {
          let arr2 = res1?.data?.map((item) => {
            return {
              value: item?._id || item?.id,
              id: item?._id || item?.id,
              label: item?.fullName || item?.firstName,
            };
          });
          console.log(res?.data, "res------------")
          let arr = res?.data?.map((item) => {
            return {
              id: item?.id || item?._id,
              value: item?.id || item?._id,
              label: item?.fullName || item?.firstName,
            };
          });
          let Multiarr2 = res1?.data?.map((item) => {
            return {
              id: item?._id || item?.id,
              name: item?.fullName || item?.firstName,
            };
          });
          let Multiarr = res?.data?.map((item) => {
            return {
              id: item?.id || item?._id,
              name: item?.fullName || item?.firstName,
            };
          });

          setmultiOptions([...Multiarr, ...Multiarr2]);
          setowners([...arr, ...arr2]);
        });
      }
    });
  };

  const handleAction = (type, id) => {
    console.log(`Action: ${type}, ID: ${id}`);
    if (type == "Edit") {
      if (!id) return;
      ApiClient.get(`okr?id=${id}`).then((res) => {
        if (res.data?.id) {
          let parentData = res.data.parentData || null;
          setdetail(res?.data);
          setform({
            id: res?.data?.id,
            title: res?.data?.title,
            session: {
              value: res?.data?.sessionTitle?.id,
              label: res?.data?.sessionTitle?.title,
            },
            ownerIds: res?.data?.ownerIds,
            parentId: res.data?.parentId || null,
            parent: parentData
              ? {
                  value: res.data?.parentId,
                  label: (
                    <div className='d-flex'>
                      <span className="mr-2 objective-img">
                        {/* <LuCircleDotDashed /> */}
                        {parentData?.keyType == "child_objective" ||
                        parentData?.keyType == "okr" ? (
                          <span className="objective-img ">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                              <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                              <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                              <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                              <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                              <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                              <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                              <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                              <circle cx="12" cy="12" r="1"></circle>
                            </svg>
                          </span>
                        ) : (
                          <span className="key-img">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                            </svg>
                          </span>
                        )}
                      </span>
                      <span>{parentData.title}</span>
                    </div>
                  ),
                  keyType: res.data.keyType,
                }
              : null,
            contributors: res?.data?.contributors,
            whyNow: res?.data?.whyNow,
          });
          setChildObjective(parentData);
          document.getElementById("CreateOKRModalOpen").click();
        }
      });
    } else if (type == "Child Objective") {
      if (!id) return; // Ensure id is defined
      
      // Use id directly for the API call
      ApiClient.get(`okr?id=${id}`).then((res) => {
        console.log("Full API Response:", res.data);
        if (res.data?.id) {
          setdetail(res?.data);
          let parentData = res.data.parentData || null;
          let payload = {
            id: res?.data?.id,
            title: res?.data?.title,
            session: {
              value: res?.data?.sessionTitle?.id,
              label: res?.data?.sessionTitle?.title,
            },
            keyType: res.data.keyType,
            parentId: res.data.parentId || null,
            ownerIds: res?.data?.ownerIds,
            contributors: res?.data?.contributors,
            whyNow: res?.data?.whyNow,
          };
          setform(payload);
          console.log("payload", payload);
          setChildObjective(parentData);
          getKeyResults(res?.data.level - 1);
          document.getElementById("CreateOKRModalOpen").click();
        }
      });
    } else if (type == "Child Key Result") {
      if (!id) return; 
      document.getElementById("OpenKeyResultChildModal").click();
      setchildKeyResult(id); 
      detailModal(id,false); //i have no idea why but adding this makes the add key result modal work, do not touch unless you understand why or have a good reason 
    } else if (type) {
      setkeyResult({
        ...id,
        ownerIds: id?.ownerDetails?.map((item) => {
          return { value: item?.id, label: item?.name };
        }),
      });
      document.getElementById("OpenKeyResultChildModal").click();
    } else {
      if (window.confirm("Do you really want to delete this okr")) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
          if (res.success) {
            setPage(1);
            getData([]);
            setHasMore(true);
            toast.success(res?.message);
            getData();
            getOKRListing();
            document.getElementById("CloseFullSideScreenModal").click();
          }
        });
      }
    }
  };
  

  const handleUpdateKeyResultModal = (item) => {
    setupdateKeyResult({
      ...updateKeyResult,
      goal: item?.targetNumber,
      currentValue: item?.initialNumber,
      newValue: item?.newValue || item?.initialNumber,
      keyResultId: item?._id || item?.id,
      confidenceLevel: item?.confidenceLevel,
      note: item?.description,
      dateOfUpdate: new Date(item?.dateOfUpdate || new Date()),
      unit: item?.unit,
    });
  };
  
  const handleKeyResultAction = (type, id) => {
    if (type == "Delete") {
      if (window.confirm(`Do you really want to delete this key result`)) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
            if (res.success) {
            getKeyResults();
            getData({},false);
          }
        });
      }
    } else if (type == "Child Objective") {
      getKeyResults(id?.okrId);
      setChildObjective(id);
      setform({ ...form, parentId: { value: id?._id || id?.id, label: id?.title } });
      document.getElementById("CreateOKRModalChildOpen").click();
    } else if (type == "EditKR") {
      getKeyResults(id?.okrId);
      setChildObjective(id);
      setkeyResult({
        ...id,
        ownerIds: id?.ownerDetails?.map((item) => {
          return { value: item?.id, label: item?.name };
        }),
      });
      document.getElementById("OpenKeyResultChildModal").click();
    }
  };


  const detailModal = (ids, value = true) => {
    let id = ids || ''
    console.log("detailModal")
    if (!layout) {
      return;
    }
    loader(true)
    ApiClient.get(`okr?id=${id}`).then((res) => {
      loader(false)
      if (res?.data?.id) {
        setdetail(res?.data);
        // setNewValue(updateKeyResult);
        setupdateKeyResult({
          ...updateKeyResult,
          okrId: res?.data?.id || res?.data?._id,
        });
        setnewupdated({
          ...updateKeyResult,
          okrId: res?.data?.id || res?.data?._id,
        });


        if (value && !childObjective) {
          document.getElementById("OpenFullSideScreenModal").click();
        }
        setChildObjective("");
        setform({ ...form, parentId: null, whyNow: '', contributors: null })
      }
    });
  };


const findOKRProgress = (data) => {
    return methodModel.findOKRProgress(data)
};

const keyResultPercent=(item)=>{
  return methodModel.keyResultPercent(item)
}

  const clearOKRForm = () => {
    setdetail("");
    setChildObjective("");
    setform({ ...form,id:'', ownerIds: "",title: "",keyType:'okr', parentId: null, whyNow: '', contributors: null })
  };

  const resources =
    dataResources &&
    dataResources.map((item) => {
      return { id: item?._id, title: item?.title };
    });

  const handleFilter = () => {
    if (filter?.ownerId && filter?.ownerId?.length > 0) {
      let arr = filter?.ownerId || ownerID;
      getData({ page: 1, ownerId: arr.map((item) => item?.value).toString() });
    } else {
      getData({ page: 1, ownerId: "" });
    }
  };

  const handlechangesessions = (e) => {
    if (e) {
      dispatch(sessions(`${e.label}`));
      setSessionIdFilter(e);
      setfilter({ ...filter, sessionId: e });
      navigate.push(`/sessions/okrs/${e.value}`);
      // localStorage.setItem("sessionID", e.value);
    }
  };


 const ConfidenceLevel = ({ data }) => {
    return <>
      <div className="confidence-level text-end">
        <span
          className={`p-2 badges-color ${data?.confidenceLevel ==
            "low" ||
            data?.confidenceLevel ==
            ""
            ? "red-color"
            : data?.confidenceLevel ==
              "medium"
              ? "orange-color"
              : data?.confidenceLevel ==
                "high"
                ? "green-color"
                : ""
            } text-capitalize`}
        >
          {data?.confidenceLevel ==
            "low" ? (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
              </svg>
              OFF
              TRACK{" "}
            </span>
          ) : data?.confidenceLevel ==
            "medium" ? (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                  fill="currentColor"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                  fill="currentColor"
                ></path>
              </svg>
              AT
              RISK
            </span>
          ) : data?.confidenceLevel ==
            "high" ? (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
              </svg>
              ON
              TRACK
            </span>
          ) : (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
              </svg>
              OFF
              TRACK
            </span>
          )}
        </span>
      </div>
    </>
  }


const [valueData, setNewValue] = useState("");
const [valueDataUnit, setNewValueUnit] = useState("");

/*=================================start test datagrid ===============================*/
const [expandedRowIds, setExpandedRowIds] = useState([]);
const [OKRFilter, setOKRFilter] = useState("keyResult");



const toggleRow = (id) => {
  setExpandedRowIds((prev) => 
    prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
  );
};


const rows = data?.map((item, index) => {
  return {
    id: item._id || index,
    objectiveName: item.title,
    progress: item.progress || 'N/A',
    keyResults: item.keyResults || 'N/A',
    actions: item,
    childObjectives: item.childObjectives || [],
    owners: item.owners || [], // Fallback to an empty array if owners is undefined
  };
});

const ObjectivesList = ({ data }) => {
  return (
    <div className="container">
      {data.map((item) => {
        const isExpanded = expandedRowIds.includes(item.id); // Check if this specific row is expanded
        return (
          <ObjectiveCard
            key={item.id}
            item={item}
            isExpanded={isExpanded}
            toggleRow={toggleRow}
          />
        );
      })}
    </div>
  );
};


const ObjectiveCard = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasKeyResults = item?.keyResults?.length > 0;

  const toggleRow = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="card mb-1">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          {/* Arrow Section */}
          <div className="d-flex align-items-center" style={{ width: '50px', justifyContent: 'center' }}>
            {hasKeyResults && (
              <div onClick={toggleRow} style={{ cursor: 'pointer' }}>
                {isExpanded ? <FaArrowDown /> : <FaArrowRight />}
              </div>
            )}
          </div>

          {/* Title Section */}
          <div className="flex-grow-1" style={{ flexBasis: '50%' }}>
            <h5 className="mb-0">
              <a
                href="#"
                className="fs-5 pointer d-flex mb-0 w-100"
                onClick={(e) => {
                  e.preventDefault();
                  const itemId = item?._id ? item._id : item.id;
                  console.log("Item object:", item);
                  console.log("Item ID:", itemId);
                  detailModal(itemId);
                }}
              >
                <span className="tdoksnames text-capitalize">
                  {item?.title}
                </span>
              </a>
            </h5>
          </div>

          {/* Progress Display Section */}
          <div className="d-flex align-items-center" style={{ flexGrow: 1, marginLeft: "10px" }}>
            <div className="paddingleft2">
              <Tooltip title="Objective Progress" placement="top">
                <span className={`progressDiv progress-color`}>
                  {findOKRProgress(item?.keyResults, 'this okr is from okr listing')}
                </span>
              </Tooltip>
            </div>
          </div>

          {/* Owner Section */}
          <div className="d-flex align-items-center" style={{ flexGrow: 1, flexBasis: '12.5%', marginLeft: "10px" }}>
            <span>Owner</span>
            <div className="ml-2 owners-number pleft owner-no-main">
              {item.ownerDetails && item.ownerDetails.length > 0 ? (
                item.ownerDetails.some(owner => owner.id) ? (
                  item.ownerDetails.slice(0, 4).map((owner, index) => (
                    owner.id ? (
                      <React.Fragment key={owner.id || index}>
                       <span 
                         key={owner.id || index} 
                         style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', backgroundColor: owner.color }}
                       >
                          <img 
                            src={methodModel.userImg(owner.image)} 
                            alt={owner.name} 
                            style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover', marginRight: '5px' }} 
                          />
                          {owner.name}
                        </span>
                      </React.Fragment>
                    ) : null
                  ))
                ) : (
                  <span>No Owners</span>
                )
              ) : (
                <span>No Owners</span>
              )}
              {item.ownerDetails && item.ownerDetails.length > 4 && <small className="pt-1">+more</small>}
            </div>
          </div>

          {/* Actions Section */}
          {user?.role !== "employee" && (
            <div className="d-flex justify-content-end" style={{ flexGrow: 1, flexBasis: '12.5%' }}>
              <div className="dropdown ml-auto">
                <button
                  className="btn"
                  type="button"
                  id={`dropdownMenuButton-${item.id}`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ background: 'none', border: 'none', padding: 10 }}
                >
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </button>
          
                <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${item.id}`}>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleAction("Child Key Result", item?._id || item?.id)}
                  >
                    <i className="fa fa-plus text-dark me-2" aria-hidden="true"></i>
                    Add Key Result
                  </a>
                  {item?.keyType === "child_objective" ? (
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleAction("Edit", item?._id || item?.id)}
                    >
                      <i className="fa fa-pen text-dark me-2" aria-hidden="true"></i>
                      Edit
                    </a>
                  ) : (
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleAction("Edit", item?._id || item?.id)}
                    >
                      <i className="fa fa-pen text-dark me-2" aria-hidden="true"></i>
                      Edit
                    </a>
                  )}
          
                  <a
                    className="dropdown-item text-danger"
                    href="#"
                    onClick={() => {
                      handleAction("Delete", item?._id);
                  }}
                  >
                    <i className="fa fa-trash text-dark me-2" aria-hidden="true"></i>
                    Delete (ne fonctione pas)
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>



        {/* Key Results Section */}
        {isExpanded && item?.keyResults?.length > 0 && (
          <div className="transition">
            {item.keyResults.map((data, index) => (
              <div className="key-result-main" key={data._id || index} style={{ padding: '10px 0' }}>
                <div className="d-flex justify-content-between align-items-center">
                  {/* Icon section */}
                  <div className="d-flex align-items-center" style={{ width: '50px', justifyContent: 'center', marginLeft:'10px' }}>
                    <MdOutlineSignalCellularAlt style={{ fontSize: '1.5rem' }} />
                  </div>

                  {/* Title and description section */}
                  <div className="flex-grow-1" style={{ flexBasis: '50%', marginLeft:"10px" }}>
                    <h6 className="mb-0">{data?.title}</h6>
                  </div>

                  {/* Progress bar section */}
                  <div className="d-flex align-items-center" style={{ flexGrow: 1, flexBasis: '12.5%', marginLeft: '10px' }}>
                    <div className="progress" style={{ width: '150px', marginRight: '10px' }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${keyResultPercent(data)}%` }} 
                        aria-valuenow={keyResultPercent(data)}  
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                    <span style={{ fontSize: '1rem' }}>{keyResultPercent(data)}%</span> 
                  </div>

                  {/* Confidence Level Badge */}
                  {data?.confidenceLevel && (
                    <div style={{ marginRight: "10px" }}>
                      <ConfidenceLevel data={data} />
                    </div>
                  )}

                  {/* Owner section */}
                  <div className="d-flex align-items-center" style={{ flexGrow: 1, flexBasis: '12.5%', marginLeft: "10px" }}>
                    <span>Owner</span>
                    <div className="ml-2 owners-number pleft owner-no-main">
                      {data.krOwners && data.krOwners.length > 0 && data.krOwners[0].id !== null ? (
                        <span 
                          style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', backgroundColor: data.krOwners[0].color}} 
                        >
                          <img 
                            src={methodModel.userImg(data.krOwners[0].image)} 
                            alt={data.krOwners[0].name} 
                            style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover', marginRight: '5px' }} 
                          />
                          {data.krOwners[0].name}
                        </span>
                      ) : (
                        <span>No Owners</span> 
                      )}
                    </div>
                  </div>
          
                  {/* Action buttons for Key Results */}
                  {user?.role !== "employee" && (
                    <div className="dropdown ml-auto">
                      <span
                        className="text-dark"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ cursor: 'pointer', padding: '10px' }}
                      >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </span>

                      <div className="dropdown-menu" aria-labelledby={`keyResultDropdown-${index}`}>
                        {/* Add Child Objective button */}
                        <span 
                          className="dropdown-item pointer" 
                          onClick={() => handleKeyResultAction("Child Objective", data)}
                        >
                          <i className="fa fa-plus text-dark me-2" aria-hidden="true"></i>
                          Add Child Objective
                        </span>

                        {/* Edit button */}
                        <span 
                          className="dropdown-item pointer" 
                          onClick={() => handleKeyResultAction("EditKR", data._id)}
                        >
                          <i className="fa fa-pen text-dark me-2" aria-hidden="true"></i>
                          Edit
                        </span>

                        {/* Delete button */}
                        <span
                           className="dropdown-item text-danger pointer"
                           onClick={(e) => {
                             e.preventDefault(); // Prevent default anchor behavior if needed
                             handleAction("Delete", item?._id);
                           }}
                         >
                           <i className="fa fa-trash text-dark me-2" aria-hidden="true"></i>
                           Delete
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


/*=================================end test datagrid ===============================*/


  return (
    <>
      <div className="inners_wrapper ">
        {data?.length >= 0 || filter?.ownerId ? (
          <>
            <div className="tbs_data_okrs">
              <div className="okrs_objec_inners mt-3">
                {layout && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <Tooltip placement="right" title="Back">
                        <span
                          className="backarrow"
                          onClick={(e) => {
                            navigate.push(`/sessions`);
                            dispatch(sessions(""));
                          }}
                        >
                          <a className="aclass">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              data-icon="goal"
                              aria-hidden="true"
                              viewBox="0 0 448 512"
                              title="back"
                            >
                              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                          </a>
                        </span>
                      </Tooltip>
                      <ul
                        class="nav nav-pills okrs_tabsul ms-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item w-50" role="presentation">
                          <button
                            class="nav-link active"
                            id="pills-objective-tab"
                            data-toggle="pill"
                            data-target="#pills-objective"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            <span className="mr-2">
                              <MdDataObject />
                            </span>
                            Objectives
                          </button>
                        </li>
                        <li class="nav-item w-50" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-allignment-tab"
                            data-toggle="pill"
                            data-target="#pills-allignment"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          // onClick={() => getData()}
                          >
                            <span className="mr-2">
                              <CiAlignRight />
                            </span>{" "}
                            Alignment
                          </button>
                        </li>
                      </ul>

                      <div className="dropdown filter-dropdown ms-3">
                        <Menu>
                          <Menu.Button
                            className="btn border-btn d-flex align-items-center"
                            onClick={(e) =>
                              setSessionIdFilter({
                                ...SessionIdFilter,
                                label: sessionss?.data,
                                value: id,
                              })
                            }
                          >
                            <CiFilter className="me-2" />
                            Filters
                            <IoIosArrowDown className="ms-2" />
                          </Menu.Button>

                          <Menu.Items>
                            <div
                              className="dropdown-menu d-block"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item "
                                onChange={(e) => e.stopPropagation()}
                              >
                                {}
                                <div className="d-flex  align-items-center">
                                  <label>Session</label>
                                  <div
                                    className="ms-2 w-100"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {LoadingState ? null : (
                                      <Select
                                        value={SessionIdFilter}
                                        isClearable={true}
                                        onChange={(e) =>
                                          handlechangesessions(e)
                                        }
                                        options={session}
                                        className="basic-multi-select text-capitalize"
                                        placeholder="Select Owners"
                                      />
                                    )}
                                  </div>
                                </div>
                              </a>
                              {user?.role != "employee" && <a
                                className="dropdown-item  mb-3"
                                onChange={(e) => e.stopPropagation()}
                              >
                                <div className="d-flex  align-items-center">
                                  <label>Owners</label>
                                  <div
                                    className="ms-2 w-100"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Select
                                      value={filter?.ownerId}
                                      isClearable={true}
                                      onChange={(e) => {
                                        setfilter({ ...filter, ownerId: e });
                                        localStorage.setItem(
                                          "ownerId",
                                          JSON.stringify(e)
                                        );
                                      }}
                                      options={owners}
                                      className="basic-multi-select text-capitalize"
                                      placeholder="Select Owners"
                                      isMulti
                                    />
                                  </div>
                                </div>
                              </a>}
                              <div className="clear-btn me-3 mb-2 d-flex align-items-center justify-content-end">
                                {(filter?.search ||
                                  filter?.ownerId ||
                                  filter?.ownerId?.length != 0) && (
                                    <>
                                      <button
                                        className="btn btn-primary me-2 filter-btn d-flex align-items-center"
                                        onClick={(e) => handleFilter()}
                                      >
                                        Filter
                                      </button>
                                      <button
                                        className="btn btn-secondary filter-btn d-flex align-items-center"
                                        onClick={(e) => {
                                          setfilter({
                                            ...filter,
                                            page: 1,
                                            search: "",
                                            ownerId: "",
                                          });
                                          getData({
                                            page: 1,
                                            search: "",
                                            ownerId: "",
                                          });
                                          localStorage.removeItem("ownerId");
                                          // localStorage.removeItem("sessionID");
                                          // localStorage.setItem(
                                          //   "ownerId",
                                          //   JSON.stringify([])
                                          // );
                                        }}
                                      >
                                        <RxCross2 className="me-2" />
                                        Clear
                                      </button>
                                    </>
                                  )}
                              </div>
                            </div>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </div>

                    {user?.role != "employee" && <div className="text-right ">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => clearOKRForm()}
                        id="OpenCreateOKRModal"
                        data-toggle="modal"
                        data-target="#OKRsModal"
                      >
                        Create OKRs
                      </button>
                    </div>}
                  </div>
                )}
                
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-objective"
                    role="tabpanel"
                    aria-labelledby="pills-objective-tab"
                  >
                  <div className="objective_tables">
                    {!loading ? (
                      <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreData} 
                        hasMore={hasMore} 
                        height={650} 
                        loader={<h4>Loading...</h4>} 
                        endMessage={
                          <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                      >
                        {/* Main container for cards */}
                        <div className="mvp_table objective-tbl-main mt-3 obj-tbl">
                          <div style={{ height: '100%', width: '100%' }}>
                            {/* Render cards progressively */}
                            {data.map((item) => {
                              const isExpanded = expandedRowIds.includes(item.id);
                              return (
                                <ObjectiveCard
                                  key={item.id}
                                  item={item}
                                  isExpanded={isExpanded}
                                  toggleRow={toggleRow}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </InfiniteScroll>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>

                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-allignment"
                    role="tabpanel"
                    aria-labelledby="pills-allignment-tab"
                  >
                    {layout ? (
                      <>
                        <AlignmentOKR
                        valueData={valueData} setNewValue={setNewValue}
                        valueDataUnit={valueDataUnit} setNewValueUnit={setNewValueUnit}
                          handleKeyResultAction={handleKeyResultAction}
                          handleAction={handleAction}
                          handleUpdateKeyResultModal={handleUpdateKeyResultModal}
                          id={id}
                          ConfidenceLevel={ConfidenceLevel}
                          findOKRProgress={findOKRProgress}
                          updateKeyResult={updateKeyResult}
                          seteditor={seteditor}
                          editor={editor}
                          keyResult={keyResult}
                          setkeyResult={setkeyResult}
                          setupdateKeyResult={setupdateKeyResult}
                          clearFields={clearFields}
                          getData={getData}
                          OKRListing={OKRListing}
                          setOKRListing={setOKRListing}
                          getOKRListing={getOKRListing}
                          data={data}
                          owners={owners}
                          getKeyResults={getKeyResults}
                          detail={detail}
                          detailModal={detailModal}
                          view={item => {
                            if (item?.keyType == "key_result") return
                            if (item?.keyType == "child_objective") {
                              detailModal(item?._id
                                ? item._id
                                : item.id)
                            } else {
                              detailModal(
                                item?._id
                                  ? item._id
                                  : item.id
                              )
                            }
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Tooltip placement="right" title="Back">
              <span
                className="backarrow"
                onClick={(e) => {
                  navigate.push(`/sessions`);
                }}
              >
                <a className="aclass">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    data-icon="goal"
                    aria-hidden="true"
                    viewBox="0 0 448 512"
                    title="back"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                </a>
              </span>
            </Tooltip>
            {}
          </>
        )}

        {layout ? (
          <>
            <ModalOKR
            sessionId={id}
            valueData={valueData} setNewValue={setNewValue}
            valueDataUnit={valueDataUnit} setNewValueUnit={setNewValueUnit}
              getOKRListing={getOKRListing}
              detail={detail}
              handleUpdateKeyResultModal={handleUpdateKeyResultModal}
              handleAction={handleAction}
              handleKeyResultAction={handleKeyResultAction}
              findOKRProgress={findOKRProgress}
              keyResult={keyResult}
              setkeyResult={setkeyResult}
              seteditor={seteditor}
              editor={editor}
              owners={owners}
              updateKeyResult={updateKeyResult}
              newupdated={newupdated}
              setupdateKeyResult={setupdateKeyResult}
              clearFields={clearFields}
              childObjective={childObjective}
              form={form}
              setform={setform}
              getData={getData}
              sessions={session}
              getKeyResults={getKeyResults}
              parentKeyResult={parentKeyResult}
              total={total}
              keyResultData={keyResultData}
              setChildObjective={setChildObjective}
              setchildKeyResult={setchildKeyResult}
              setkeyResultData={setkeyResultData}
              setparentKeyResult={setparentKeyResult}
              detailModal={detailModal}
              childKeyResult={childKeyResult}
              result={e => {
                if (e.event == 'Parent Submit' || e.event == 'Child Submit') {
                  detailModal(
                    e.value?._id
                      ? e.value?._id
                      : e.value?.id
                  )
                }
              }}
            />
          </>
        ) : (
          <></>
        )}

        {layout ? (
          <>
            <ChildModalOKR
            valueData={valueData} setNewValue={setNewValue}
            valueDataUnit={valueDataUnit} setNewValueUnit={setNewValueUnit}
              detail={detail}
              handleAction={handleAction}
              findOKRProgress={findOKRProgress}
              keyResult={keyResult}
              setkeyResult={setkeyResult}
              seteditor={seteditor}
              editor={editor}
              owners={owners}
              updateKeyResult={updateKeyResult}
              newupdated={newupdated}
              setupdateKeyResult={setupdateKeyResult}
              clearFields={clearFields}
              childObjective={childObjective}
              form={form}
              setform={setform}
              getData={getData}
              sessions={session}
              getKeyResults={getKeyResults}
              parentKeyResult={parentKeyResult}
              total={total}
              keyResultData={keyResultData}
              setChildObjective={setChildObjective}
              setkeyResultData={setkeyResultData}
              setparentKeyResult={setparentKeyResult}
              detailModal={detailModal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OKRindex;
